import { ProjectSettings } from '@valiot/settings'
import packageJson from '../package.json'
import Logo from 'src/components/Logo/Logo'
const settings: ProjectSettings = {
  version: packageJson.version,
  title: 'Bydsa - Valiot',
  copilotEnabled: true,
  api: {
    http: import.meta.env.VITE_API_URL,
    wss: import.meta.env.VITE_API_WSS,
    valiotWorker: import.meta.env.VITE_WORKER,
    ssoUrl: import.meta.env.VITE_SSO_URL,
    routerCode: import.meta.env.VITE_ROUTER_CODE,
  },
  customerLogo: {
    src: <Logo />,
  },
  analytics: {
    enabled: true,
    siteId: import.meta.env.VITE_MATOMO_SITE_ID,
  },
  locale: {
    default: 'es',
    projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  },
  support: {
    enabled: true,
  },
  kioskMode: {
    enabled: false,
  },
  tenancy: { selectionMode: 'one', enabled: true },
  sidebarDefault: 'expanded',
  home: 'maintenance/schedule',
  routes: [],
  dropdowns: [],
  sections: [
    {
      path: 'sequence',
      title: 'Secuencia',
      directory: 'sequence',
      routes: [
        {
          path: 'timeline-sequence',
          text: 'Cronograma',
          icon: 'calendar-week',
          iconSource: 'fas',
          component: 'TimelineSequence',
          sidebarDefault: 'expanded',
        },
        {
          path: 'timeline-list',
          text: 'Secuencia detallada',
          icon: 'calendar-week',
          iconSource: 'fas',
          component: 'ListSequence',
          sidebarDefault: 'expanded',
        },
      ],
      dropdowns: [],
    },
    // TODO: uncomment when PlanningSequence is ready
    // {
    //   path: 'production',
    //   title: 'Producción',
    //   directory: 'production',
    //   dropdowns: [],
    //   routes: [
    //     {
    //       path: 'generate',
    //       text: 'Programar secuencia',
    //       iconSource: 'fas',
    //       icon: 'calendar-star',
    //       component: 'PlanningSequence',
    //       sidebarDefault: 'expanded',
    //     },
    //   ],
    // },
    {
      path: 'mps',
      title: 'MPS',
      directory: 'mps',
      routes: [
        {
          path: 'sales-orders',
          text: 'Pedidos',
          icon: 'money-check-alt',
          component: 'SalesOrdersMTO',
          permissions: 'all',
        },
        {
          path: 'generate-requirements',
          text: 'Requerimientos de Producción',
          iconSource: 'fas',
          icon: 'list-alt',
          component: 'ProductionRequirement',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
      ],
      dropdowns: [],
    },
    {
      path: 'inventory',
      title: 'Inventarios',
      directory: 'inventory',
      dropdowns: [
        {
          path: 'replenishment',
          text: 'Reabastecimiento',
          icon: 'plus-square',
          directory: 'replenishment',
          permissions: 'all',
          routes: [
            {
              path: 'requirements',
              text: 'Requerimientos',
              icon: 'tasks',
              component: 'Requirements',
              permissions: 'all',
            },
            {
              path: 'orders',
              text: 'Órdenes',
              icon: 'dolly-flatbed',
              component: 'Orders',
              permissions: 'all',
            },
          ],
        },
      ],
      routes: [
        {
          path: 'raw-materials',
          text: 'Materias Primas',
          icon: 'inventory',
          component: 'RawMaterials',
        },
        {
          path: 'wip-materials',
          text: 'Material Semiterminado',
          icon: 'inventory',
          component: 'WIPMaterials',
        },
        {
          path: 'finished-products',
          text: 'Productos Terminados',
          icon: 'inventory',
          component: 'FinishedProducts',
        },
      ],
    },
    {
      path: 'maintenance',
      title: 'Mantenimiento',
      directory: 'maintenance',
      routes: [
        {
          path: 'schedule',
          text: 'Paros',
          icon: 'tools',
          component: 'Stops',
          permissions: 'all',
        },
        {
          path: 'stops-manage',
          text: 'Administrar motivos paros',
          component: 'ManageReasonsStops',
          permissions: 'all',
          hidden: true,
        },
      ],
      dropdowns: [],
    },
    {
      path: 'setup',
      title: 'Configuración',
      directory: 'setup',
      routes: [],
      dropdowns: [
        {
          path: 'operation',
          text: 'Datos Operativos',
          directory: 'operation',
          icon: 'industry-alt',
          permissions: 'all',
          routes: [
            {
              path: 'factories',
              text: 'Plantas',
              icon: 'industry-alt',
              component: 'Facilities',
              permissions: 'all',
            },
            {
              path: 'warehouses',
              text: 'Almacenes',
              component: 'Warehouses',
              permissions: 'all',
            },
            {
              path: 'machineries',
              text: 'Maquinarias',
              component: 'Machineries',
              permissions: 'all',
            },
            {
              path: 'processes',
              text: 'Procesos',
              component: 'Processes',
              permissions: 'all',
            },
            {
              path: 'customers',
              text: 'Clientes',
              component: 'Customers',
              permissions: 'all',
            },
            {
              path: 'suppliers',
              text: 'Proveedores',
              component: 'Suppliers',
              permissions: 'all',
            },
            {
              path: 'raw-materials',
              text: 'Materias Primas',
              component: 'RawMaterials',
              permissions: 'all',
            },
            {
              path: 'wip-materials',
              text: 'Material Semiterminado',
              component: 'WIPMaterials',
              permissions: 'all',
            },
            {
              path: 'finished-products',
              text: 'Productos Terminados',
              component: 'FinishedProducts',
              permissions: 'all',
            },
            {
              path: 'changeovers',
              text: 'Changeovers',
              component: 'Changeovers',
              permissions: 'all',
            },
            {
              path: 'relation-process-resources',
              text: 'Relación Procesos a Recursos',
              component: 'ResourcesToProcess',
              permissions: 'all',
            },
            {
              path: 'relation-resources-materials',
              text: 'Relación Materiales a Recursos',
              component: 'MaterialsToResources',
              permissions: 'all',
            },
            {
              path: 'relation-processes-materials',
              text: 'Relación Materiales a Procesos',
              component: 'MaterialsToProcess',
              permissions: 'all',
            },
            {
              path: 'safety-stock',
              text: 'Safety Stock',
              component: 'SafetyStock',
              permissions: 'all',
            },
          ],
        },
        {
          path: 'connectivity',
          text: 'Conectividad',
          icon: 'link',
          directory: 'connectivity',
          permissions: 'all',
          routes: [
            {
              path: 'health',
              text: 'Monitores',
              icon: 'heartbeat',
              component: 'Monitors',
              permissions: 'all',
            },

            {
              path: 'properties',
              text: 'Propiedades',
              component: 'PropertyCatalogues',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'units',
              text: 'Unidades',
              component: 'Units',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'property-categories',
              text: 'Categorías',
              component: 'PropertyCategories',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'currencies',
              text: 'Monedas',
              component: 'Currencies',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          path: 'admin',
          text: 'Administración',
          directory: 'admin',
          icon: 'user-shield',
          permissions: 'all',
          routes: [
            {
              path: 'users',
              text: 'Usuarios',
              iconSource: 'fas',
              icon: 'user',
              component: 'Users',
              permissions: 'all',
              // exactUrl: false,
              hidden: false,
            },
            {
              path: 'user-groups',
              text: 'Grupos',
              iconSource: 'fas',
              icon: 'users',
              component: 'Groups',
              permissions: 'all',
              // exactUrl: false,
              hidden: false,
            },
            {
              path: 'facility-allocation',
              text: 'Asignación a Plantas',
              iconSource: 'fas',
              icon: 'industry',
              component: 'TenancyAdmin',
              exactUrl: false,
              hidden: false,
            },
          ],
        },
      ],
    },
  ],
}

export default settings
